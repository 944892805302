<template>
  <svg
    :width="`${(big ? 180 : 36)}px`"
    :height="`${(big ? 180 : 36)}px`"
    viewBox="0 0 480 480">
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="-49.425"
      y1="565.075"
      x2="-4.325"
      y2="610.175"
      gradientTransform="matrix(8 0 0 -8 455 4941)">
      <stop
        offset="0"
        style="stop-color:#FF5F6D"/>
      <stop
        offset="1"
        style="stop-color:#FFC371"/>
    </linearGradient>
    <path
      style="fill:url(#SVGID_1_);"
      d="M440,96H40c-22.4,0-40,17.6-40,40v208c0,22.4,17.6,40,40,40h400c22.4,0,40-17.6,40-40V136
	C480,113.6,462.4,96,440,96z M16,144h448v32H16V144z M40,112h400c10.4,0,19.2,6.4,22.4,16H17.6C20.8,118.4,29.6,112,40,112z M440,368H40c-13.6,0-24-10.4-24-24V192h448v152C464,357.6,453.6,368,440,368z"/>
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="-48.5812"
      y1="585.9172"
      x2="-43.1673"
      y2="591.3342"
      gradientTransform="matrix(8 0 0 -8 455 4941)">
      <stop
        offset="0"
        style="stop-color:#FF5F6D"/>
      <stop
        offset="1"
        style="stop-color:#FFC371"/>
    </linearGradient>
    <path
      style="fill:url(#SVGID_2_);"
      d="M56,240h64c4.8,0,8-3.2,8-8s-3.2-8-8-8H56c-4.8,0-8,3.2-8,8S51.2,240,56,240z"/>
    <linearGradient
      id="SVGID_3_"
      gradientUnits="userSpaceOnUse"
      x1="-43.5812"
      y1="567.9172"
      x2="-28.1672"
      y2="583.3342"
      gradientTransform="matrix(8 0 0 -8 455 4941)">
      <stop
        offset="0"
        style="stop-color:#FF5F6D"/>
      <stop
        offset="1"
        style="stop-color:#FFC371"/>
    </linearGradient>
    <path
      style="fill:url(#SVGID_3_);"
      d="M280,328H56c-4.8,0-8,3.2-8,8s3.2,8,8,8h224c4.8,0,8-3.2,8-8S284.8,328,280,328z"/>
    <linearGradient
      id="SVGID_4_"
      gradientUnits="userSpaceOnUse"
      x1="-10.9955"
      y1="573.5045"
      x2="-2.7545"
      y2="581.7455"
      gradientTransform="matrix(8 0 0 -8 455 4941)">
      <stop
        offset="0"
        style="stop-color:#FF5F6D"/>
      <stop
        offset="1"
        style="stop-color:#FFC371"/>
    </linearGradient>
    <path
      style="fill:url(#SVGID_4_);"
      d="M424,288h-48c-13.6,0-24,10.4-24,24v16c0,13.6,10.4,24,24,24h48c13.6,0,24-10.4,24-24v-16
	C448,298.4,437.6,288,424,288z M432,328c0,4.8-3.2,8-8,8h-48c-4.8,0-8-3.2-8-8v-16c0-4.8,3.2-8,8-8h48c4.8,0,8,3.2,8,8V328z"/>
  </svg>
</template>
<script>
export default {
  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
};
</script>
