<template>
  <div
    v-if="!trialMessage"
    style="cursor: pointer;"
    @click="$router.push({ name: 'Planos - Visão geral' })"
  >
    <el-alert
      v-if="data && data.days_remaining > 0"
      :closable="false"
      :title="`Faltam ${Math.max(0, data.days_remaining)} dias!`"
      class="subscription-alert"
      type="warning"
      show-icon
    >
      <p class="el-alert__description">
        Sua loja está em periodo de testes que acaba em {{ data.end | formatDate }},
        clique <strong>aqui</strong> para assinar um de nossos planos.
      </p>
    </el-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from 'moment';

export default {
  filters: {
    formatDate: date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
  },
  data() {
    return {
      data: null,
      trialMessage: false,
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE,
      info: types.STORE_INFO
    }),
  },
  watch: {
    storeID: async function(newValue) {
      this.getStatus(newValue);
    },
  },
    async mounted() {
    this.getStatus(this.storeID);
    try {
      await this.$store.dispatch(types.STORE_INFO, this.storeID);
      this.trialMessage = this.info.hide_trial_message;
    } catch(error) {
       if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
    }
  },
  methods: {
    async getStatus(storeId) {
      try {
        if (storeId) {
          const resp = await this.$store.dispatch(types.STORE_TRIAL_STATUS, storeId);
          this.data = resp;
        }
      } catch(error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .subscription-alert {
    a {
      font-weight: 700;
      color: #e6a23c;
    }
  }
</style>
