<template>
  <header class="store-header">
    <font-awesome-icon
      v-show="!hamburguerOn"
      class="hamburguer-icon"
      icon="bars"
      size="lg"
      style="color: #FAFAFA; margin: 0 10px 0 20px; cursor: pointer;"
      @click="$emit('toggleMenu'); hamburguerChange()"
    />
    <font-awesome-icon
      v-show="hamburguerOn"
      class="hamburguer-icon"
      icon="window-close"
      size="lg"
      style="color: #FAFAFA; margin: 0 10px 0 20px; cursor: pointer;"
      @click="$emit('toggleMenu'); hamburguerChange()"
    />
    <Logo
      :alternative="false"
      :is-mobile="$isMobile"
      width="121"
      height="62"
      class="header-logo"
      @click.native="$router.push('/dashboard')"
    />
    <div v-if="!loading" class="header-content">
      <el-button
        v-if="!$isMobile && trial_info && trial_info.trial_days !== -1 && !isIOS"
        type="primary"
        size="small"
        round
        @click="$router.push({ name: 'Planos - Visão geral' })"
      >Assine Já</el-button>
      <div class="card-wrapper">
        <el-tooltip
          class="header-card"
          effect="dark"
          content="Cartão Neopag"
          placement="bottom"
          @click.native="$router.push({ name: 'Cartão Neopag' })"
        >
          <img v-if="storeInfo.card" :src="storeInfo.card" class="store-card" />
          <card-graphic v-else />
        </el-tooltip>
      </div>
      <font-awesome-icon
        icon="question-circle"
        size="lg"
        style="color: #FAFAFA; margin-left: 14px; cursor: pointer;"
        @click="$router.push('/ajuda')"
      />
      <font-awesome-icon
        v-if="currentStore.permissions && currentStore.permissions.settings"
        icon="cog"
        size="lg"
        style="color: #FAFAFA; margin-left: 14px; cursor: pointer;"
        @click="$router.push('/configuracoes')"
      />
      <el-dropdown class="header-menu" trigger="click" style="background: #6c737c">
        <span class="el-dropdown-link">
          <font-awesome-icon
            icon="user"
            size="lg"
            style="color: #FAFAFA; margin: 0 20px 0 14px; cursor: pointer;"
          />
        </span>
        <el-dropdown-menu slot="dropdown">
          <div class="dropdown-content">
            <h4>{{ storeInfo ? storeInfo.name : '' }}{{ computedBranchName }}</h4>
            <h4>{{ authUser ? authUser.name : '' }}</h4>
          </div>
          <el-dropdown-item divided @click.native="handleLogout">
            <font-awesome-icon
              icon="sign-out-alt"
              size="lg"
              style="color: #FAFAFA; margin-right: 8px;"
            />
            <p>Sair</p>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else class="header-content">
      <i class="el-icon-loading" />
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faUser,
  faBars,
  faQuestionCircle,
  faSignOutAlt,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import * as types from "@/store/types";
import Logo from "@/components/Logo";
import CardGraphic from "@/components/CardGraphic";

library.add(
  faCog,
  faUser,
  faBars,
  faQuestionCircle,
  faSignOutAlt,
  faWindowClose
);

export default {
  components: {
    Logo,
    CardGraphic,
    FontAwesomeIcon
  },
  props: {
    isMenuOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      customersInterval: undefined,
      activePlanIndex: 0,
      activePlan: undefined,
      loading: false,
      trial_info: null,
      hamburguerOn: false
    };
  },
  computed: {
    ...mapGetters({
      authUser: types.AUTH_USER,
      storeInfo: types.STORE_INFO,
      storeUsers: types.STORE_USERS,
      branches: types.BRANCH_LIST,
      plans: types.SUBSCRIPTION_PLANS,
      currentBranch: types.AUTH_ACTIVE_BRANCH,
      subscription: types.SUBSCRIPTION_DETAILS,
      currentStore: types.AUTH_ACTIVE_STORE_DETAILS,
      customersToApprove: types.STORE_CUSTOMERS_TO_APPROVE
    }),
    isIOS() {
      if (
        (navigator && navigator.appVersion.indexOf("iPhone") !== -1) ||
        (navigator && navigator.appVersion.indexOf("iPad") !== -1)
      ) {
        return true;
      }
      return false;
    },
    computedBranchName() {
      if (this.currentBranch && this.branches && this.branches.length > 0) {
        const name = this.branches.find(it => it.id === this.currentBranch)
          .name;
        if (this.storeInfo && name !== this.storeInfo.name) {
          return (
            " - " + this.branches.find(it => it.id === this.currentBranch).name
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    showBadge() {
      return this.badgeCount > 0;
    },
    badgeCount() {
      return this.customersToApprove.length;
    }
  },
  async mounted() {
    this.$bus.$on("openMenu", () => {
      this.hamburguerOn = true;
    });
    this.$bus.$on("closeMenu", () => {
      this.hamburguerOn = false;
    });
    // this.loading = true;
    this.customersInterval = setInterval(
      this.updateCustomersToanalyze,
      10 * 60 * 1000
    );
    this.updateCustomersToanalyze();
    try {
      const trials = await this.$store.dispatch(
        types.STORE_TRIAL_STATUS,
        this.currentStore.id
      );
      this.trial_info = trials;
      await this.$store.dispatch(types.STORE_INFO, this.currentStore.id);
      await this.$store.dispatch(
        types.SUBSCRIPTION_DETAILS,
        this.currentStore.id
      );
      await this.$store.dispatch(types.SUBSCRIPTION_PLANS);
      // this.loading = false;
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  destroyed() {
    clearInterval(this.customersInterval);
  },
  methods: {
    hamburguerChange: function() {
      this.hamburguerOn = !this.hamburguerOn;
    },
    async updateCustomersToanalyze() {
      try {
        await this.$store.dispatch(types.STORE_CUSTOMERS_TO_APPROVE, {
          storeID: this.$store.getters[types.AUTH_ACTIVE_STORE]
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async handleLogout() {
      try {
        await this.$store.dispatch(types.AUTH_LOGOUT);
        this.$router.push("/login");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  background: $preto;
  .el-dropdown-menu__item--divided {
    &:before {
      background-color: $preto;
    }
    border-color: #fafafa;
    p {
      display: inline-block;
      color: #fafafa;
      margin: 0;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
    &:focus {
      background: rgba(255, 255, 255, 0.4);
    }
    &:active {
      background: rgba(255, 255, 255, 0.4);
    }
  }
  .dropdown-content {
    h4 {
      width: 300px;
      padding: 0 20px;
      text-overflow: ellipsis;
      color: #fafafa;
    }
    h4 + h4 {
      padding: 10px 20px;
    }
  }
}
.store-header {
  position: relative;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  background: #6c737c;

  @include box-shadow(1);

  .el-icon {
    color: #ffffff;
  }

  > .header-toggle {
    margin-left: 8px;
    cursor: pointer;
  }

  > .header-toggle.--open {
    margin-left: 18px;
    margin-right: 10px;
  }

  > .header-logo {
    margin-left: 8px;
    cursor: pointer;
  }

  > .header-content {
    margin-left: auto;
    font-family: $title-font;
    color: $preto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    p {
      flex: 0 180px;
      display: flex;
      flex-flow: row wrap;
      width: fit-content;
      font-size: 14px;
      color: #ffffff;
    }
    span {
      font-weight: bolder;
      margin-left: auto;
      font-family: monospace;
    }

    @include large-down {
      width: auto;
      p {
        flex: 0 auto;
      }
    }

    .card-wrapper {
      border: 2px solid #fafafa;
      border-radius: 5px;
      overflow: hidden;
      height: 44px;
      margin-left: 64px;
    }
    .el-button {
      width: 120px;
    }
    .el-icon-loading {
      color: #fafafa;
      font-size: 26px;
      margin-right: 42px;
    }

    @media screen and (max-width: 425px) {
      position: absolute;
      right: 0;
    }
  }

  > .header-card {
    margin-left: 1rem;
    @include large-down {
      margin-left: auto;
    }
  }

  .store-card {
    width: auto;
    height: 40px;
  }

  > .header-menu {
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer;

    @include large-down {
      margin-left: 1rem;
    }

    .el-dropdown-link {
      display: flex;
      align-items: center;
    }
  }
}

.el-dropdown-menu .store-user-plan {
  padding: 1rem;
  font-family: $title-font;
  color: $preto;
  display: flex;
  flex-flow: row wrap;
  width: 200px;
  p {
    margin-top: 1rem;
    flex: 0 200px;
    font-size: 11px;
    display: flex;
    flex-flow: row wrap;
    width: fit-content;
    + p {
      margin-top: 0.25rem;
    }
  }
  span {
    font-weight: bolder;
    margin-left: auto;
    font-family: monospace;
  }
}

.store-user-plan {
  .el-badge {
    display: block;
  }
  .store-name {
    display: block;
    font-size: 15px;
  }
  .store-plan {
    display: block;
    font-size: 11px;
    width: fit-content;
    padding: 3px;
    color: #fff;
    margin-top: 4px;
    line-height: 1;
    border-radius: 4px;
    text-transform: uppercase;
  }
  .hamburguer-icon {
    transition: 0.2s 0.3s ease;
  }
}
</style>
