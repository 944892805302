<template>
  <el-alert
    v-if="data"
    :closable="false"
    class="subscription-alert"
    title="Atenção!"
    type="error"
    show-icon
  >
    <p class="el-alert__description">
      Sua fatura com vencimento em {{ data.due_date | formatDate }}
      está atrasada em {{ data.days_overdue }} dias
      <a
        :href="data.url"
        target="_blank"
        rel="noreferrer"
      >clique aqui para realizar o pagamento.</a>
    </p>
  </el-alert>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";

export default {
  filters: {
    formatDate: date => moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
  },
  data() {
    return {
      data: null
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE
    })
  },
  watch: {
    storeID: async function(newValue) {
      this.getStatus(newValue);
    }
  },
  mounted() {
    this.getStatus(this.storeID);
  },
  methods: {
    async getStatus(storeId) {
      try {
        if (storeId) {
          const resp = await this.$store.dispatch(
            types.STORE_INVOICE_STATUS,
            storeId
          );
          this.data = resp;
        }
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subscription-alert {
  a {
    font-weight: 700;
    color: red;
  }
}
</style>
