<template>
  <div class="neopag" @keyup.esc="isMenuOpen = false">
    <div v-if="showEmailConfirmation" :loading="sendingEmail" class="confirm-email">
      <i icon="el-icon-warning" />
      Por favor clique no link de confirmação enviado para o email &nbsp;{{
      activeStore.email
      }}. Você precisa completar esse passo para confirmarmos sua identidade.
      <br />Caso não tenha recebido o email,
      <a href="#" @click="resendConfirmationEmail()">Clique aqui</a> para
      reenviá-lo.
    </div>
    <!-- <el-dialog
      :visible.sync="showNewClients"
      :width="$isMobile ? '100%' : '50%'"
      append-to-body>
      <new-clients-dialog @approve="goToApproveRoute"/>
    </el-dialog>-->

    <header-container
      v-if="authOrigin !== 'app'"
      :is-menu-open="isMenuOpen"
      class="header-container"
      @toggleMenu="isMenuOpen = !isMenuOpen"
    />

    <menu-container v-if="authOrigin !== 'app'" :is-open="isMenuOpen" class="menu-container" />

    <section class="content-container">
      <store-subscription-alert />
      <store-trial-alert />
      <navigator-alert />
      <transition name="fade">
        <div v-if="isMenuOpen" class="backdrop" @click="isMenuOpen = false" />
      </transition>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </section>
  </div>
</template>

<script>
import initHelpHero from "helphero";
import HeaderContainer from "@/containers/Header";
import MenuContainer from "@/containers/Menu";
// import NewClientsDialog from "@/components/NewClientsDialog";
import StoreSubscriptionAlert from "@/components/StoreSubscriptionAlert";
import StoreTrialAlert from "@/components/StoreTrialAlert";

import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  components: {
    HeaderContainer,
    MenuContainer,
    StoreSubscriptionAlert,
    StoreTrialAlert
  },
  data() {
    return {
      is_neopag_credit_config: false,
      isMenuOpen: false,
      sendingEmail: false,
      showNewClients: false,
      egg: new this.$egg()
    };
  },
  computed: {
    ...mapGetters({
      config: types.STORE_CONFIG,
      authOrigin: types.AUTH_ORIGIN,
      user: types.AUTH_USER,
      info: types.STORE_INFO,
      storeID: types.AUTH_ACTIVE_STORE,
      branchID: types.AUTH_ACTIVE_BRANCH,
      activeStore: types.AUTH_ACTIVE_STORE_DETAILS,
      newClients: types.STORE_CUSTOMERS_TO_APPROVE,
      showEmailFeedback: types.AUTH_SHOW_EMAIL_FEEDBACK,
      storeUsers: types.STORE_USERS,
      subscription: types.SUBSCRIPTION_DETAILS
    }),
    showEmailConfirmation() {
      if (!this.info.id) {
        return false;
      }
      return !this.info.email_confirmed;
    }
  },
  watch: {
    isMenuOpen(val) {
      this.$bus.$emit("menu", val);
    },
    subscription: async function(newValue) {
      try {
        if (!this.$isDev) {
          const trial_info = await this.$store.dispatch(
            types.STORE_TRIAL_STATUS,
            this.storeID
          );
          const invoice_status = await this.$store.dispatch(
            types.STORE_INVOICE_STATUS,
            this.storeID
          );
        }
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    storeUsers: async function(newValue) {
      try {
        if (!this.$isDev) {
          const trial_info = await this.$store.dispatch(
            types.STORE_TRIAL_STATUS,
            this.storeID
          );
          const invoice_status = await this.$store.dispatch(
            types.STORE_INVOICE_STATUS,
            this.storeID
          );
        }
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.$isMobile) {
      this.isMenuOpen = false;
    }
    next();
  },
  created() {
    this.egg
      .addCode("esc,left", () => {
        this.isMenuOpen = !this.isMenuOpen;
      })
      .listen();
  },
  async mounted() {
    try {
      const trial_info = await this.$store.dispatch(
        types.STORE_TRIAL_STATUS,
        this.storeID
      );
      const invoice_status = await this.$store.dispatch(
        types.STORE_INVOICE_STATUS,
        this.storeID
      );
      await this.$store.dispatch(types.STAFF_ACTIVE, {
        storeID: this.storeID,
        userID: this.user.id
      });

      if (this.showEmailFeedback) {
        this.$notify.success("Email confirmado!");
      }

      this.$bus.$on("openMenu", () => {
        this.isMenuOpen = true;
      });

      this.$bus.$on("closeMenu", () => {
        this.isMenuOpen = false;
      });
      if (this.$route.redirectedFrom === "/" && !this.showEmailFeedback) {
        await this.$store
          .dispatch(types.STORE_CUSTOMERS_TO_APPROVE, {
            storeID: this.storeID
          })
          .then(() => {
            if (this.newClients.length) {
              this.showNewClients = true;
            }
          });
      }

    /*  await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
      this.is_neopag_credit_config = this.config.is_neopag_credit_config;

     window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "wiezbc5h",
        name: this.user.name,
        phone: this.user.phone,
        "Neopag_Hibrido": this.config.is_neopag_credit_config,
        store: this.activeStore.name,
        company: {
        "company_id": this.storeID,
        "company_name": this.activeStore.name,
        "name": this.activeStore.name,
        "cnpj": this.activeStore.cnpj,
        "active_users": this.storeUsers.active,
        "queried_users": this.storeUsers.rejected,
        "users_total": this.storeUsers.active + this.storeUsers.rejected,
        "Neopag_Hibrido": this.config.is_neopag_credit_config,
        "plano_assinatura": this.subscription && this.subscription.plan
            ? this.subscription.plan.name
            : ""
      }};

      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wiezbc5h';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

      const hlp = initHelpHero("lLD8GTFmIsm");
      hlp.identify(this.user.id, {
        name: this.user.name,
        phone: this.user.phone,
        user: this.user.name,
        email: this.user.email,
        store: this.activeStore.name,
        user_id: this.user.id,
        store_id: this.activeStore.id,
        branch_id: this.branchID,
        active_users: this.storeUsers.active,
        queried_users: this.storeUsers.rejected,
        users_total: this.storeUsers.active + this.storeUsers.rejected,
        plan:
          this.subscription && this.subscription.plan
            ? this.subscription.plan.name
            : "",
        client_number:
          this.subscription && this.subscription.plan
            ? this.subscription.plan.clients_number
            : 0
      });*/

    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    goToApproveRoute() {
      this.showNewClients = false;
      this.$router.push({
        name: "Aprovar clientes"
      });
    },
    async resendConfirmationEmail() {
      this.sendingEmail = true;
      await this.$store
        .dispatch(types.AUTH_EMAIL_CONFIRMATION, this.storeID)
        .then(() => {
          this.$notify.success(
            "Email enviado. Verifique sua caixa de entrada."
          );
        })
        .catch(() => {
          this.$notify.error(
            "Não foi possível enviar o email. Tente novamente mais tarde."
          );
        })
        .finally(() => {
          this.sendingEmail = false;
        });
    }
  }
};
</script>

<style lang="scss">
.neopag {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100%;

  &__welcome {
    .el-dialog__title {
      font-family: $title-font;
      color: $preto;
    }
    @include medium-down {
      .el-dialog__body {
        padding-top: 0;
      }
    }
  }

  .confirm-email {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    min-height: 2.25rem;
    background: rgba(red, 0.75);
    padding: 1rem 1rem;
    line-height: 1.55;
    text-transform: uppercase;
    color: #fff;
    font-size: 11px;
    text-align: center;
    a {
      color: #fff;
    }
  }

  .header-container {
    flex: 0 100%;
  }

  .menu-container,
  .content-container {
    min-height: calc(100% - 3.5rem);
  }

  .menu-container {
    flex: 0 auto;
  }

  .content-container {
    flex: 1;
    min-width: calc(100% - 280px);
    max-width: calc(100% - 66px);

    @include small-down {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    background: #fff;
  }

  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.52);

    @include small-up {
      display: none;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
