<template>
	<nav :class="{ menu: true, 'menu--open': isOpen }">
		<el-menu :router="true" @close="$bus.$emit('closeMenu')">
			<template v-for="(s, index) in menuItems">
				<el-menu-item
					v-if="!s.badge && !s.subMenu"
					:key="index"
					:route="{ name: s.routeName }"
					:data-intro="s.hint"
					:index="s.index"
					data-position="right"
				>
					<template v-if="s.icon" slot="title">
						<div :class="s.class">
							<font-awesome-icon :icon="s.icon" size="lg" style="color: #FAFAFA;" />
							<span class="text">{{ s.label }}</span>
						</div>
					</template>
				</el-menu-item>
				<el-menu-item
					v-else-if="s.badge && !s.subMenu"
					:key="index"
					:route="{ name: s.routeName }"
					:index="s.index"
					data-position="right"
				>
					<el-badge
						:hidden="!(s.badge === 'client' ? clientBadge : billetBadge)"
						:value="s.badge === 'client' ? clientCount : billetCount"
						:max="99"
						class="menu-badge"
					>
						<font-awesome-icon :icon="s.icon" size="lg" style="color: #FAFAFA;" />
					</el-badge>
					<span class="text">{{ s.label }}</span>
				</el-menu-item>
				<el-menu-item
					v-else-if="!s.subMenu"
					:key="index"
					:route="{ name: s.routeName }"
					:data-intro="s.hint"
					:data-step="index + 1"
					:index="s.index"
					data-position="right"
				>
					<font-awesome-icon v-if="!s.icon" :icon="s.icon" size="lg" style="color: #FAFAFA;" />
					<span class="text">{{ s.label }}</span>
				</el-menu-item>
				<!-- eslint-disable-next-line -->
				<div @click="toggleMenuIcon()">
					<el-submenu v-if="s.subMenu" :index="s.index" :data-intro="s.hint" data-position="right">
						<template slot="title">
							<font-awesome-icon :icon="s.icon" size="lg" style="color: #FAFAFA;" />
							<span class="text">{{ s.label }}</span>
						</template>
						<el-menu-item-group v-if="isOpen" :title="s.subMenu.group.title">
							<el-menu-item
								v-for="subItem in s.subMenu.subItems"
								:key="subItem.index"
								:route="{ path: `${subItem.route}` }"
								:index="subItem.index"
							>
								<span class="text">{{ subItem.name }}</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
				</div>
			</template>
			<el-button
				v-if="
					isOpen &&
						$isMobile &&
						trial_info &&
						trial_info.trial_days !== -1 &&
						!isIOS
				"
				type="primary"
				size="small"
				style="margin: 32px 0 0 32px; width: 200px;"
				round
				@click="$router.push({ name: 'Planos - Visão geral' })"
			>Assine Já</el-button>
		</el-menu>
	</nav>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import introJs from "intro.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faThLarge,
	faUsers,
	faSearch,
	faUserPlus,
	faMoneyCheckAlt,
	faCashRegister,
	faHandshake,
	faCalendarAlt,
	faClipboardList,
	faStar
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
	faThLarge,
	faUsers,
	faSearch,
	faUserPlus,
	faCashRegister,
	faMoneyCheckAlt,
	faHandshake,
	faCalendarAlt,
	faClipboardList,
	faStar
);
export default {
	components: {
		FontAwesomeIcon
	},
	props: {
		isOpen: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
      is_neopag_credit_config: false,
			trial_info: null,
      subscriptionDetails: null
		};
	},
	computed: {
		...mapGetters({
			storeID: types.AUTH_ACTIVE_STORE,
			subscription: types.SUBSCRIPTION_CONFIG,
			activeStore: types.AUTH_ACTIVE_STORE_DETAILS,
			pendingBillets: types.REMITTANCE_PENDING_BILLETS,
			customersToApprove: types.STORE_CUSTOMERS_TO_APPROVE,
			info: types.STORE_INFO,
      config: types.STORE_CONFIG,
		}),
		showRemittanceMenu() {
			return (
				this.info.create_billets &&
				this.subscription.allow_billets &&
				this.subscription.iugu_activated &&
				this.info.billet_config === "manual"
			);
		},
		clientBadge() {
			return this.clientCount > 0;
		},
		clientCount() {
			return this.customersToApprove.length;
		},
		billetBadge() {
			return this.billetCount > 0;
		},
		billetCount() {
			return this.pendingBillets.length;
		},
		isIOS() {
			if (
				(navigator && navigator.appVersion.indexOf("iPhone") !== -1) ||
				(navigator && navigator.appVersion.indexOf("iPad") !== -1)
			) {
				return true;
			}
			return false;
		},
		menuItems() {
			const items = [
				{
					hint:
						"Acompanhe os principais indicadores de sua loja de forma simples, intuitiva e poderosa.",
					routeName: "Dashboard",
					index: "dashboard",
					icon: "th-large",
					label: "Painel",
					name: "dashboard"
				},
        {
					hint:
						"Cadastro de Clientes com Análise de Crédito ou Cadastro Manual",
					routeName: "Cadastrar Clientes",
					index: "consulta-neopag",
					icon: "user-plus",
					label: "Cadastro",
					name: "add_clients",
					subMenu: {
						group: {
							title: "Formas de Cadastro"
						},
						subItems: [
							{
								index: "consulta-neopag",
								route: "/consulta-neopag",
                				icon: 'search',
								name: "Análise de Crédito"
							},
							{
								index: "cadastrar-cliente",
								route: "/cadastrar-cliente",
                				icon: "user-plus",
								name: "Cadastro Manual"
							}
						]
					}
				},
/*
        {
          hint:
            'Basta digitar o CPF que fazemos a avaliação de risco e crédito através do inovador score de confiança Neopag.',
          routeName: 'Consulta Neopag - CPF',
          index: 'consulta-neopag',
          icon: 'search',
          label: 'Consultar CPF',
          name: 'add_clients',
        },
        {
          hint:
            "Aqui você pode manualmente adicionar novos usuários à sua loja.",
          routeName: "Cadastro de Cliente",
          index: "cadastrar-cliente",
          icon: "user-plus",
          label: "Cadastrar Cliente",
          name: "register_clients",
        },
*/
				{
					badge: "client",
					hint:
						"Tenha acesso a todos seus clientes, informações cadastrais, contatos, risco e crédito.",
					routeName: "Clientes",
					index: "clientes",
					icon: "users",
					label: "Clientes",
					name: "clients"
					// subMenu: {
					//   group: {
					//     title: "Tipos de cliente"
					//   },
					//   subItems: [
					//     {
					//       index: "clientes-neopag",
					//       route: "/clientes",
					//       name: "Clientes Neopag"
					//     },
					//     {
					//       index: "relatorio-clientes",
					//       route: "/relatorio-clientes",
					//       name: "Relatório de clientes"
					//     }
					//   ]
					// }
				},
				{
					hint:
						"Faça suas VENDAS de forma simples e rápida. Informe o cliente, valor e parcelas. Pronto, a partir dai fazemos toda a gestão.",
					routeName: "Detalhes da venda",
					index: "venda",
					icon: "cash-register",
					label: "Venda",
					name: "sales"
				},
				{
					hint:
						"Informe o cliente, as faturas e valor recebido. Você pode fazer o recebimento parcial que fazemos todo o controle do saldo com seu cliente.",
					routeName: "Recebimento",
					index: "recebimento",
					icon: "money-check-alt",
					label: "Recebimento",
					name: "payments",
					subMenu: {
						group: {
							title: "Formas de recebimento"
						},
						subItems: [
							{
								index: "recebimento",
								route: "/recebimento/detalhes",
								name: "Presencial"
							},
							{
								index: "recebimento-boleto",
								route: "/recebimento-boleto/detalhes",
								name: "Boleto/PIX"
							}
						]
					}
				},
				// {
				//   hint:
				//     "Informe o cliente, as faturas e valor recebido. Você pode fazer o recebimento parcial que fazemos todo o controle do saldo com seu cliente.",
				//   routeName: "Recebimento",
				//   index: "recebimento",
				//   icon: "money-check-alt",
				//   label: "Recebimento",
				//   name: "payments"
				// },
				{
					hint:
						"Você pode fazer acordos de dívidas ou simplesmente reparcelar as compras para comodidade de seus clientes.",
					routeName: "Detalhes do reparcelamento",
					index: "acordos-e-reparcelamento",
					icon: "handshake",
					label: "Reparcelamento",
					name: "deals"
				},
				{
					hint:
						"Acompanhe todas as etapas do processo automático de cobrança e recuperação de crédito.",
					routeName: "Esteira de cobrança",
					index: "esteira",
					icon: "calendar-alt",
					label: "Cobranças",
					name: "charging",
					subMenu: {
						group: {
							title: "Tipos de relatórios"
						},
						subItems: [
							{
								index: "cobranças-painel",
								route: "/esteira-de-cobranca",
								name: "Painel"
							},
							{
								index: "cobranças-ações",
								route: "/esteira-de-cobranca-acoes",
								name: "Ações de Cobrança"
							},
							{
								index: "cobranças-relatorio",
								route: "/esteira-de-cobranca-relatorio",
								name: "Clientes Pendentes"
							}
						]
					}
				},
				{
					hint:
						"Acompanhe em detalhes e gerencie todas as atividades de sua loja.",
					routeName: "Relatórios",
					index: "relatorios",
					icon: "clipboard-list",
					label: "Relatórios",
					name: "reports",
					subMenu: {
						group: {
							title: "Tipos de relatórios"
						},
						subItems: [
							{
								index: "relatorios-vendas",
								route: "/reports/vendas",
								name: "Vendas"
							},
							{
								index: "relatorios-recebimentos",
								route: "/reports/recebimentos",
								name: "Recebimentos"
							},
							{
								index: "relatorios-acordos",
								route: "/reports/acordos",
								name: "Acordos"
							},
							{
								index: "relatorios-faturas",
								route: "/reports/faturas",
								name: "Faturas"
							},
							{
								index: "relatorios-boletos",
								route: "/reports/boletos",
								name: "Boletos"
							},
							{
								index: "dynamic_1009",
								route: "/reports/dynamic_1009",
								name: "Clientes por Faixa"
							},
							{
								index: "dynamic_1010",
								route: "/reports/dynamic_1010",
								name: "Clientes quitados"
							},
							{
								index: "dynamic_1011",
								route: "/reports/dynamic_1011",
								name: "Clientes sem Compras"
							},
							{
								index: "dynamic_1012",
								route: "/reports/dynamic_1012",
								name: "Clientes posição geral"
							},
							{
								index: "dynamic_1021",
								route: "/reports/dynamic_1021",
								name: "Informações Cadastrais Clientes"
							},
							{
								index: "dynamic_1022",
								route: "/reports/dynamic_1022",
								name: "Devedores por participação"
							},
							{
								index: "dynamic_1034",
								route: "/reports/dynamic_1034",
								name: "CRM WhattsApp"
							},
              ...(this.is_neopag_credit_config ? [
                {
                  index: "dynamic_1122",
                  route: "/reports/dynamic_1122",
                  name: "Repasses - Neopag Crédito"
                }
              ] : [])
						]
					}
				},
				{
					hint: "",
					routeName: "",
					index: "hack1",
					icon: "",
					label: "",
					name: "hack1"
				},
				// {
				// 	hint: "Você pode consultar as promoções vigentes na plataforma.",
				// 	routeName: "Indicações",
				// 	index: "indicacoes",
				// 	icon: "star",
				// 	label: "Indicações",
				// 	name: "indications",
				// 	class: "indications"
				// }
			];
			if (this.showRemittanceMenu) {
				items.splice(4, 0, {
					badge: "billet",
					routeName: "Arquivos de remessa e retorno",
					index: "remittance",
					icon: "el-icon-upload",
					label: "Ctrl. Remessa e retorno"
				});
			}
			const filtered = items.filter(item => {
				if (
					this.activeStore.permissions &&
					this.activeStore.permissions.hasOwnProperty(item.name)
				) {
					return this.activeStore.permissions[item.name];
				}
				return true;
			});
			return filtered;
		}
	},
	watch: {
		$route() {
			this.$forceUpdate();
		}
	},
	async mounted() {
		this.$bus.$on("show-intro", this.showIntro);
		try {
      const subscriptionDetails = await this.$store.dispatch(types.SUBSCRIPTION_DETAILS, this.storeID);
      this.subscriptionDetails = subscriptionDetails

      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
      this.is_neopag_credit_config = this.config.is_neopag_credit_config;

      const trials = await this.$store.dispatch(
				types.STORE_TRIAL_STATUS,
				this.storeID
			);
			this.trial_info = trials;
			await this.$store.dispatch(types.REMITTANCE_PENDING_BILLETS, this.storeID);
		} catch (error) {
			if (this.$raven && error.status === 500) {
				this.$raven.captureException(error.message, { req: error });
			}
			this.$notify.error({ title: error.message });
		}
	},
	methods: {
		showIntro() {
			const skipIntro = this.$cookie.get("intro");
			if (!skipIntro) {
				introJs()
					.setOptions({
						doneLabel: "Fim",
						hintButtonLabel: "Entendi",
						nextLabel: "Avançar &rarr;",
						prevLabel: "&larr; Voltar",
						skipLabel: "Pular",
						tooltipPosition: "right"
					})
					.oncomplete(this.onIntroComplete)
					.start();
			}
		},
		onIntroComplete() {
			this.$cookie.set("intro", true, {
				expires: "1Y"
			});
		},
		toggleMenuIcon: function() {
			this.$bus.$emit("openMenu");
		},
	}
};
</script>

<style lang="scss">
.menu {
	z-index: 10;
	flex: 0 66px;
	width: 66px;
	text-transform: uppercase;
	transition: 0.2s 0.3s ease;
	.el-menu {
		height: 100%;
		padding-top: 1rem;
		background: $preto;
	}
	.el-submenu__icon-arrow {
		display: none;
	}
	.el-submenu {
		transition: 0.2s 0.3s ease;
	}
	.el-menu-item,
	.el-submenu__title {
		overflow: hidden;
		svg {
			margin: 0 12px 0 0;
		}
		&:hover {
			background: rgba(255, 255, 255, 0.4);
		}
		&:focus {
			background: rgba(255, 255, 255, 0.4);
		}
		&:active {
			background: rgba(255, 255, 255, 0.4);
		}
	}
	.indications {
		animation-name: bounce;
		animation-duration: 0.5s;
		animation-iteration-count: infinite;
		svg {
			color: yellow !important;
		}
	}
	@keyframes bounce {
		0% {
			// transform: translateY(-10px);
			transform: rotate(-2deg)
		}
		50% {
			transform: rotate(2deg)
			// transform: translateY(0);
		}
		100% {
			transform: rotate(-2deg);
			// transform: translateY(-10px);
		}
	}
	.is-active {
		background: rgba(255, 255, 255, 0.4);
	}
	.el-menu-item [class^="el-icon-"] {
		margin-right: 0;
	}
	.text {
		font-family: $title-font;
		font-size: 0.75rem;
		line-height: 1.2rem;
		opacity: 0;
		transition: 0.3s opacity linear;
		position: absolute;
		left: 0;
		top: 20px;
		padding-left: 60px;
		color: #fafafa;
	}
	@include small-down {
		position: absolute;
		top: 62px;
		width: 280px;
		height: 100%;
		transform: translate3d(-100%, 0, 0);
	}
	&--open {
		flex: 0 240px;
		width: 240px;
		transition: 0.3s ease;
		.text {
			opacity: 1;
			transition: 0.2s 0.3s opacity linear;
		}
		@include small-down {
			transform: translate3d(0, 0, 0);
		}
	}
	.menu-badge {
		position: relative;
		sup {
			top: 12px;
			right: 22px;
		}
		i {
			position: relative;
		}
		.text {
			position: relative;
		}
	}
}
.menu--open {
	.el-submenu__icon-arrow {
		display: block;
		color: #fff;
	}
}
.introjs-helperNumberLayer {
	left: 4px;
	top: 0;
	background: $laranja;
	animation: pulseHelper 2s infinite;
}
.introjs-tooltip {
	min-width: 320px;
}
.introjs-tooltiptext {
	padding: 0.5rem;
	font-size: 1rem;
	font-family: $title-font;
	line-height: 1.4;
	color: darken($preto, 20);
}
@keyframes pulseHelper {
	0% {
		box-shadow: 0 0 0 0 rgba($laranja, 1);
	}
	70% {
		box-shadow: 0 0 0 10px rgba($laranja, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba($laranja, 0);
	}
}
</style>
